import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import account_icon from '../assets/svg/user-icon.svg'
import list_icon from '../assets/svg/list-icon.svg'
import location_icon from '../assets/svg/location-icon.svg'
import heart_icon from '../assets/svg/heart.svg'
import key_icon from '../assets/svg/key-icon.svg'
import mail_icon from '../assets/svg/mail-icon.svg'
import logout_icon from '../assets/svg/logout-icon.svg'

import cart_icon from '../assets/svg/cart-icon.svg';
import map_icon from '../assets/svg/map-icon.svg';
import map_icon_active from '../assets/svg/map-icon-active.svg';
import cart_done_icon_active from '../assets/svg/cart-done-icon-active.svg';

import cart_done_icon from '../assets/svg/cart-done-icon.svg';
import cc_icon from '../assets/svg/credit-card-icon.svg';
import delete_icon from '../assets/svg/delete-icon.svg';
import paypal_icon from '../assets/images/paypal.png';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


class OrderDonePage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            step: 4
        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }




        if (this.props[0].match.params.paypalReturnInfo && this.props[0].location.search.indexOf("?") !== -1 && this.props.socketIOClient && !this.state._paymentExecuted) {
            // alert("test");

            let paypalReturnInfo = this.props[0].location.search.replace("?", "");
            console.log(paypalReturnInfo);
            let broken = paypalReturnInfo.split("&");
            let fields = {};


            for (let i = 0; i < broken.length; i++) {
                fields[broken[i].split("=")[0]] = broken[i].split("=")[1];
            }

            console.log(fields);
            this.setState({
                _paymentExecuted: true
            }, () => {
                this.props.socketIOClient.emit("paypalExecute", fields);

            })

            //PayerID
            //paymentId
        }



    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('paypalExecute', (data) => {
            if (data.successful) {
                this.setState({
                    step: 4
                })
            }
        });




    }



    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    render() {

        return (
            <div className="account-wrap cart-wrap">

                {
                    /* !this.props.uData ? <Redirect to='/login' ></Redirect> : null}*/
                }

                <Container>
                    <Row>
                        <Col lg="3" className="d-none d-lg-block">
                            <div className="categories">
                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />

                            </div>
                        </Col>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="cart-progress">

                                            <li className={this.state.step === 0 ? 'active' : 'done'}>
                                                <div className="icon">
                                                    <Isvg src={cart_icon} />
                                                </div>
                                                <p>{this.props.translate('1. Warenkorb')}</p>
                                                <p>{this.props.translate('Mengen, Versand & Zahlung')}</p>

                                            </li>

                                            <li className={this.state.step === 1 ? 'active' : this.state.step > 1 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 1 ? map_icon_active : map_icon} />
                                                </div>
                                                <p>{this.props.translate('2. Adresse')}</p>
                                                <p>{this.props.translate('Rechnungs- und Lieferadresse')}</p>

                                            </li>

                                            <li className={this.state.step === 2 ? 'active' : this.state.step > 2 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 2 ? cart_done_icon_active : cart_done_icon} />
                                                </div>
                                                <p>{this.props.translate('3. Bestellen')}</p>
                                                <p>{this.props.translate('Bestellung prüfen und bestätigen')}</p>

                                            </li>

                                            <li className={this.state.step === 3 ? 'active' : this.state.step > 3 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={cc_icon} />
                                                </div>
                                                <p>{this.props.translate('4. Zahlung')}</p>
                                                <p>{this.props.translate('Bezahlung über PayPal PLUS')}</p>

                                            </li>

                                        </ul>
                                    </Col>
                                    <Col lg="12">

                                        {this.state.step === 4 ?
                                            <Container className="box-container">
                                                <Row>
                                                    <Col lg="12">
                                                        <h3>{this.props.translate('Danke dir! Ihre Bestellung wurde erfolgreich abgeschlossen!')}</h3>
                                                        <p>
                                                            {this.props.translate('Wir bedanken uns für Ihre Bestellung! Wenn Sie Probleme haben oder wenn wir Ihnen helfen können, zögern Sie bitte nicht, uns zu kontaktieren.')}

                                                        </p>
                                                        <br/>
                                                        <Link className="button" to='/'>{this.props.translate('Mit dem Einkaufen fortfahren')}</Link>

                                                    </Col>

                                                </Row>
                                            </Container>

                                            : null}

                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(OrderDonePage));
