import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import quote_icon from '../assets/svg/quote-icon.svg';


import {
    Container,
    Row,
    Col,
    CarouselItem,
    Carousel,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';


export class ReviewsSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            activeIndex: 0
        };
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length ? 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const { activeIndex } = this.state;

        const slides = this.props.items.map((item, idx) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}
                    className="review"
                >
                    <Container>
                        <Row>
                            <Col lg={{ size: 2, offset: 1 }}>
                                <img src={item.image.image} />
                            </Col>
                            <Col lg={{ size: 8 }}>
                                <Isvg src={quote_icon} />
                                <p>
                                    {item.message}
                                </p>

                                <p className="user">
                                    - {item.name}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </CarouselItem>
            );
        });


        return (
            <section className="section section-reviews">
            <Container>
                <Row>
                    <Col lg="12">
                        <Carousel
                            activeIndex={activeIndex}
                            next={this.next}
                            previous={this.previous}
                            className="reviews-slider carousel-fade"
                        >
                            <CarouselIndicators items={this.props.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                            {slides}
                        </Carousel>

                    </Col>
                </Row>
            </Container>
        </section>
)
    }
}

export default ReviewsSlider;