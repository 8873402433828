import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';
import banner3 from '../assets/images/banner3.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import BlogArticle from '../components/blogArticle';
import HomeSlider from '../components/homeSlider';
import ReviewsSlider from '../components/reviewsSlider';
import Slider from "react-slick";
import titleLeafs from '../assets/svg/title-leafs.svg'
import titleLeafsWhite from '../assets/svg/title-leafs-white.svg'

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            activeIndex: 0,
            banners: [
            ],

            slides: [

            ],

        };

    }



    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchSlides', (data) => {
            console.log(data);
            this.setState({
                slides: data
            })
        });

        this.props.socketIOClient.on('fetchBanners', (data) => {
            console.log(data);
            this.setState({
                banners: data
            })
        });

        this.props.socketIOClient.emit("fetchPopularProducts", {});

        this.props.socketIOClient.emit("fetchSlides", {});

        this.props.socketIOClient.emit("fetchBanners", {});

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }



    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth < 768 ? 2 : 4,
            slidesToScroll: 1
        };

        console.log(this.props.categories);
        return (
            <div className="home-wrap">


                <HomeSlider items={this.state.slides} translate={this.props.translate} />

                <section className="section section-banners">

                    <Container>
                        <Row>
                            {this.state.banners && this.state.banners[0] ?
                                <Col lg="4">
                                    <div className="banner">
                                        <img src={this.state.banners[0].Image} />
                                        <div>
                                            <h6>{this.state.banners[0].title}</h6>
                                        </div>
                                    </div>
                                </Col>
                                :
                                null
                            }
                            {this.state.banners && this.state.banners[1] ?
                                <Col lg="4">
                                    <div className="banner">
                                        <img src={this.state.banners[1].Image} />
                                        <div>
                                            <h6>{this.state.banners[1].title}</h6>
                                        </div>
                                    </div>
                                </Col>
                                :
                                null
                            }
                             {this.state.banners && this.state.banners[2] ?
                                <Col lg="4">
                                    <div className="banner">
                                        <img src={this.state.banners[2].Image} />
                                        <div>
                                            <h6>{this.state.banners[2].title}</h6>
                                        </div>
                                    </div>
                                </Col>
                                :
                                null
                            }
                        </Row>
                    </Container>
                </section>


                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    Izdvojeni <span>artikli</span>
                                </h2>
                            </Col>

                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.popularProducts.map((item, idx) => {
                                            return (
                                                <Article
                                                    _id={item._id}
                                                    alias={item.Alias}
                                                    image={item.Images && item.Images.length && item.Images[0]}
                                                    title={item.Name}
                                                    discount={item.discount}
                                                    currency={this.props.currency}
                                                    package={item.package}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}>
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col>


                        </Row>
                    </Container>
                </section>

                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    Novo <span>u ponudi</span>
                                </h2>
                            </Col>

                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.newestProducts.map((item, idx) => {
                                            return (
                                                <Article
                                                    _id={item._id}
                                                    alias={item.Alias}
                                                    image={item.Images && item.Images.length && item.Images[0]}
                                                    title={item.Name}
                                                    discount={item.discount}
                                                    currency={this.props.currency}
                                                    package={item.package}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}>
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col>


                        </Row>
                    </Container>
                </section>





                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">

                                <h2>
                                    Najprodavaniji <span>artikli</span>
                                </h2>
                            </Col>

                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.popularProducts.map((item, idx) => {
                                            return (
                                                <Article
                                                    _id={item._id}
                                                    alias={item.Alias}
                                                    image={item.Images && item.Images.length && item.Images[0]}
                                                    title={item.Name}
                                                    package={item.package}
                                                    currency={this.props.currency}
                                                    discount={item.discount}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}>
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col>


                        </Row>
                    </Container>
                </section>



                {/* <ReviewsSlider items={this.state.reviews} />*/}


                <section className="section section-news">
                    <Container>
                        <Row>
                            <Col lg="12" >

                                <h2>
                                    Novosti i aktuelnosti
                                </h2>
                            </Col>
                            {
                                this.props.news.map((item, idx) => {
                                    if (idx < 3)
                                        return (
                                            <Col lg="4">
                                                <BlogArticle {...item} translate={this.props.translate}></BlogArticle>
                                            </Col>
                                        )
                                })
                            }

                            <Col lg="12" className="btn-wrap">
                                <Link to='/novosti'>
                                    <button className="button">
                                        POGLEDAJ SVE
                                </button>
                                </Link>
                            </Col>

                        </Row>
                    </Container>
                </section>



                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(HomePage));
