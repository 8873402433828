import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';

import Text from './fields/text';
import Textarea from './fields/textarea';


const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderTextAreaField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )


const contactForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>

            <h6>
                {props.translate('Da biste nam poslali poruku, jednostavno ispunite sva polja označena * i kliknite na "Pošalji".')}

            </h6>

            <Field
                name="FirstName"
                type="text"
                component={renderTextField}
                label={props.translate('Ime *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="LastName"
                type="text"
                component={renderTextField}
                label={props.translate('Prezime *')}
                placeholder=""
                validate={required}

            ></Field>

            
            <Field
                name="EMail"
                type="email"
                component={renderTextField}
                label={props.translate('E-Mail adresa*')}
                placeholder=""
                validate={required}

            ></Field>


            <Field
                name="Telephone"
                type="text"
                component={renderTextField}
                label={props.translate('Telefon')}
                placeholder=""
            ></Field>
            <p>{props.translate('Ako navedete telefonski broj, rado ćemo vas nazvati.')}</p>
            <br />

            <Field
                name="Subject"
                type="text"
                component={renderTextField}
                label={props.translate('Tema *')}
                placeholder=""
                validate={required}
            ></Field>

            <Field
                name="Message"
                type="text"
                component={renderTextAreaField}
                label={props.translate('Vaša poruka*')}
                placeholder=""
                validate={required}

            ></Field>







            <button type="submit" className="button">{props.translate('Pošalji')}</button>


        </form>

    )
}

export default reduxForm({
    form: 'contactForm'  // a unique identifier for this form
})(contactForm)
