

import React, { Component } from 'react';
import Routes from './routes'
import socketIO from 'socket.io-client';
import popup_bg from './assets/images/popupbg.png';
import coupon from './assets/images/coupon.png';


Number.prototype.formatPrice = function (currency) {
  let price = this;
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}

String.prototype.formatPrice = function (currency) {
  let price = parseFloat(this);
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}


class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.updateBreadcrumb = this.updateBreadcrumb.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.allowCookies = this.allowCookies.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    window.googleMapsCallback = this.googleMapsCallback;


    this.state = {
      lang: 'de',
      breadcrumb: [],
      categories: [],
      uData: null,
      cartCount: 0,
      newestProducts: [],
      popularProducts: [],
      news: [],
      infoMessages: {

      },
      currency: {
        code: 'KM',
        exchangeRate: 1
      },
      pages: {
        'kako-naruciti': {},
        'privacy-policy': {},
        'terms-and-conditions': {}
      }

    }
  }


  showInfoMessage(text, error) {
    //console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }

  hidePopup() {
    localStorage.popup = Math.floor(Date.now() / 1000);
    //console.log(true)
    this.setState({
      popup: null
    })
  }

  allowCookies() {
    localStorage.allowCookies = true;
    this.setState({
      cookies: true
    });
  }


  updateBreadcrumb(bcrumb) {
    this.setState({
      breadcrumb: bcrumb
    });
  }

  googleMapsCallback() {
    this.setState({
      _googleMapsLoaded: true
    });
  }

  setUserData(data) {
    this.setState({
      uData: data
    });

    localStorage.uData = JSON.stringify(data);

  }

  componentDidMount() {

    if (localStorage.popup) {
      if (parseInt(localStorage.popup) + 24 * 60 * 60 < Math.floor(Date.now() / 1000)) {
        //alert(true);
        this.setState({
          popup: true
        })
      }
    } else {
      this.setState({
        popup: true
      })
    }

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

    let socket = socketIO('https://madaco-socket.novamedia.agency');
    //let socket = socketIO('192.168.0.178:4001');

    //let socket = socketIO('http://35.198.145.129:4001');
    //console.log(socket);
    this.setState({
      socketIOClient: socket
    });


    if (localStorage.uData) {
      let uData = JSON.parse(localStorage.uData);

      if (uData && uData.User && uData.User.EMail && uData.User.pk)
        socket.emit("userVerify", { email: uData.User.EMail, pk: uData.User.pk });
    }


    socket.on('cartInfo', (data) => {
      this.setState({
        cartCount: data.count
      })
    })
    socket.on('fetchPages', (data) => {
      console.log(data);
      this.setState({
        pages: data
      });
    });

    socket.on('userVerify', (data) => {
      //console.log(data);
      if (data.successful) {
        this.setUserData(data.user);
      }

      socket.emit('cartInfo', {});

    });

    socket.on('fetchNewestProducts', (data) => {
      this.setState({
        newestProducts: data
      });
    })

    socket.on('fetchPopularProducts', (data) => {
      this.setState({
        popularProducts: data
      })
    });



    socket.on('fetchCategories', (data) => {
      //console.log(data);
      this.setState({
        categories: data
      });
    });

    socket.on('fetchNews', (data) => {
      //console.log(data);
      this.setState({
        news: data
      });
    });


    socket.on('fetchPopup', (data) => {
      //console.log(data);
      this.setState({
        popupData: data
      });
    });



    socket.on('userLogout', (data) => {
      socket.emit('cartInfo', {});
    })

    socket.emit('fetchCategories', {});
    socket.emit('fetchNewestProducts', {});
    socket.emit('fetchPopularProducts', {});
    socket.emit('fetchNews', {});
    socket.emit('fetchPages', {});

    socket.on('siteData', (data) => {
      //console.log(data);
      this.setState({
        siteData: data
      });
    });


  }


  translate(text) {
    return text;
  }

  render() {

    return (
      <div>
        <Routes
          translate={this.translate}
          updateBreadcrumb={this.updateBreadcrumb}
          setUserData={this.setUserData}
          allowCookies={this.allowCookies}
          showInfoMessage={this.showInfoMessage}
          hideInfoMessage={this.hideInfoMessage}
          hidePopup={this.hidePopup}

          {...this.state}
        />
        <div className="pop-up-messages">
          {
            Object.values(this.state.infoMessages).map((item, idx) => {
              //console.log(item);
              return (
                <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>
                  <i className="mdi mdi-close hide" />

                  <p className={item.error ? 'error' : ''}>{item.error ? <i className="mdi mdi-close" ></i> : null}{item.message}</p>
                </div>
              )
            })
          }

        </div>

        {this.state.popup && this.state.popupData ?
          <div className="popup">
            <img src={this.state.popupData.Background} className="overlay" />
            <div className="content">
              <img src={this.state.popupData.Image} className="coupon" />
              <h2>{this.state.popupData.Title}</h2>
              <h3>{this.state.popupData.Subtitle}</h3>
              <h6>{this.state.popupData.Text}</h6>
              <button className="button" onClick={() => this.hidePopup()}>{this.translate('BEGINN MIT DEM EINKAUF')}</button>
            </div>
          </div>
          : null
        }

      </div>

    );

  }

}

export default App;
