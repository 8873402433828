import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


class ImprintPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }






    render() {
        let product = this.state.product;

        return (
            <div className="account-wrap">




                <Container>
                    <Row>
                        <Col lg="3" className="d-none d-lg-block">
                            <div className="categories">
                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />

                            </div>
                        </Col>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                            <li><Link to='/'>{this.props.translate('Početna')}</Link></li>

                                            <li><Link to='/customer-information'>{this.props.translate('Impressum')}</Link></li>

                                        </ul>
                                    </Col>
                                    <Col lg="12">
                                        <h1>{this.props.translate('Impressum')}</h1>
                                    </Col>
                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="12" >
                                                    <h3>Impressum</h3>
                                                    <div>&nbsp;</div>
                                                    <table class="Imprint">
                                                        <tbody>
                                                            <tr>
                                                                <td class="Label">Adresse</td>
                                                                <td>A-key GmbH<br />
                                                                    <p>Ziegelei 10<br />89542 Herbrechtingen</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="Label">Telefon</td>
                                                                <td>07324 / 9 87 88 66</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="Label">Fax</td>
                                                                <td>07324 / 9 87 88 68</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="Label">Gesch&auml;ftsf&uuml;hrer</td>
                                                                <td>H&uuml;seyin Aksahin</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="Label">E-Mail</td>
                                                                <td><a id="ObscureImprint" href="http://a-key-gmbh.com/epages/7a45e58b-0e5c-4c5c-b15e-adc09374c3aa.sf/de_DE/bWFpbHRvOmluZm9AYS1rZXktZ21iaC5jb20=" rel="nofollow">info@a-key-gmbh.com</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="ImprintText">
                                                        <p><br /><strong>Steueridentifikation</strong><br />USt-IdNr.: DE 309756673<br />Steuer-Nr.: 64002/242100</p>
                                                        <p><strong>Handesregister</strong><br />Amtsgericht: Ulm<br />Register-Nr.: HRB734554</p>
                                                        <p><br /><strong>Bankverbindung</strong><br />Name der Bank: Sparkasse Heidenheim<br />IBAN: DE20632500300046040457<br />BIC: SOLADES1HDH<br />Kontoinhaber: A-key GmbH</p>
                                                        <p>Gl&auml;ubiger-ID: DE81ZZZ00001989727</p>

                                                        <p>Die in diesem Shop aufgeführten Marken oder geschäftlichen Bezeichnungen
dienen ausschließlich als Hinweis der Bestimmung der angebotenen Waren
und Dienstleistungen, insbesondere als Zubehör oder Ersatzteil.
Zwischen den Inhabern der aufgeführten Marken bzw. geschäftlichen
Bezeichnungen und der A-Key GmbH bestehen keine wirtschaftlichen
Beziehungen.
Insbesondere werden keine Originalprodukte oder auturisierte Produkte
der Inhaber der aufgeführten Marken bzw. geschäftlichen Bezeichnungen
angeboten.
<br /><br />

                                                            Sollte der Inhalt auf www.a-key-gmbh.com fremde Rechte oder gesetzliche
                                                            Bestimmungen verletzen, bitten wir um entsprechende Nachricht ohne dafür
                                                            Kosten in Berechnung zu stellen. Wir garantieren, die zu Recht
                                                            beanstandeten Inhalte unverzüglich zu entfernen, ohne dass von Ihrer
                                                            Seite die Einschaltung eines Rechtsanwaltes erforderlich ist. Sollten
                                                            wir dennoch eine Abmahnung mit Kostenberechnung ohne vorherige
                                                            Kontaktaufnahme erhalten, werden wir diese vollumfänglich zurückweisen
                                                            und gegebenenfalls Gegenklage wegen Verletzung vorgenannter Bestimmungen
einreichen.</p>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Container>



                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(ImprintPage));
