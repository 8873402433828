import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleMobileSearchForm, handleMenu } from '../../actions/index';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';
import { changeLanguage } from '../../actions';
import Categories from '../../components/categories';

import facebook_icon from '../../assets/images/fb-header.svg'
import instagram_icon from '../../assets/images/instagram-header.svg'
import credit_card_icon from '../../assets/svg/credit-card-icon.svg'
import heart_icon from '../../assets/svg/heart-icon.svg'
import account_icon from '../../assets/svg/account-icon.svg'
import cart_icon from '../../assets/svg/cart-icon.svg'
import phone_icon from '../../assets/svg/phone-icon.svg'
import hamburger_icon from '../../assets/svg/hamburger-icon.svg'
import home_icon from '../../assets/svg/home-icon.svg'
import phone1_icon from '../../assets/svg/phone.svg'
import category_icon from '../../assets/svg/category-icon.svg'
import searchIcon from '../../assets/svg/search.svg'
import searchIconWhite from '../../assets/svg/search-white.svg'

import logo from '../../assets/images/logo.png';
import image from '../../assets/images/no-image.jpg';


import drawerAccount from '../../assets/svg/drawer/account.svg';
import drawerCart from '../../assets/svg/drawer/cart.svg';
import drawerHome from '../../assets/svg/drawer/home.svg';
import drawerLogout from '../../assets/svg/drawer/logout.svg';
import drawerPhone from '../../assets/svg/drawer/phone.svg';
import drawerWishlist from '../../assets/svg/drawer/wishlist.svg';


class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [],
            search: '',
            imageErrors: {},
            width: 0, height: 0,
            showSearch: false
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }





    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('suggestions', (data) => {
            console.log(data);

            this.setState({
                imageErrors: {},
                suggestions: data.products
            })
        });



    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    suggestions() {
        if (this.state.search.length) {
            this.props.socketIOClient.emit("suggestions", { search: this.state.search });
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

        if (this.leftMenuRef && !this.leftMenuRef.contains(event.target)) {
            this.setState({
                leftMenu: null
            })
        }

        if (this.rightMenuRef && !this.rightMenuRef.contains(event.target)) {
            this.setState({
                rightMenu: null
            })
        }
    }


    render() {
        return (
            <header>
                <div className="header-top">
                    <Container>
                        <Row>
                            <Col lg="12" className="header-top-container">
                                <Container>
                                    <Row>
                                        <Col lg="4" className="actions">
                                            <Link to='/cart'>
                                                <Isvg src={credit_card_icon} />
                                                Završi kupovinu
                                </Link>
                                            <Link to='/account/wishlist'>
                                                <Isvg src={heart_icon} />
                                                Lista omiljenih
                                </Link>
                                        </Col>
                                        <Col lg="4">
                                        </Col>

                                        <Col lg="4" className="social">
                                            <div className="social">
                                                <span>Pratite nas</span>
                                                <a href='https://www.facebook.com/parfimerija.ds/' target="_blank"><Isvg src={facebook_icon} /></a>
                                                <a href='https://www.instagram.com/parfimerija.ds' target="_blank"><Isvg src={instagram_icon} /></a>

                                            </div>
                                        </Col>


                                    </Row>
                                </Container>

                            </Col>


                        </Row>
                    </Container>
                </div>

                <Container className="header">
                    <Row>
                        <Col lg={{ size: 2, order: 0 }} xs={{ size: 2, order: 0 }} className='hamburger d-lg-none d-xl-none' onClick={() => { this.setState({ leftMenu: !this.state.leftMenu }) }}>
                            <Isvg src={hamburger_icon} />
                        </Col>

                        <Col lg={{ size: 6, order: 1 }} xs={{ size: 6, order: 2 }} className="search-form d-none d-sm-none d-md-none d-xl-block">
                            <input onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.props[0].history.push(`/category/&search=${this.state.search}`)
                                }
                            }} onFocus={this.suggestions} value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }, () => this.suggestions()) }} type="text" placeholder={"Pretražite..."} />
                            <i className="mdi mdi-magnify d-block d-lg-none" />
                            <button onClick={() => {
                                this.props[0].history.push(`/category/&search=${this.state.search}`)
                            }}><Isvg src={searchIcon} /></button>
                            {this.state.suggestions.length ?
                                <div className="search-results" ref={(node) => { this.wrapperRef = node; }}>
                                    <h6>Artikli</h6>
                                    <ul>
                                        {
                                            this.state.suggestions.map((item, idx) => {
                                                return (
                                                    <li key={idx}><Link to={`/product/${item.Alias}/${item._id}`}><img onError={() => {
                                                        let imageErrors = this.state.imageErrors;
                                                        imageErrors[item.Image] = true;
                                                        this.setState({ imageErrors })
                                                    }} src={!this.state.imageErrors[item.Images && item.Images.length && item.Images[0]] ? (item.Images && item.Images.length && item.Images[0]) ? item.Images[0] : image : image} />{item.Name}</Link></li>

                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                                : null}
                        </Col>
                        <Col xl={{ size: 3, order: 0 }} xs={{ size: 5, order: 1 }} sm={{ size: 5, order: 1 }} md={{ size: 8, order: 1 }} className="logo">
                            <div>
                                <Link to='/'><img src={logo} /></Link>
                            </div>
                        </Col>

                        {this.props.uData ?
                            <Col lg={{ size: 3, order: 2 }} md={{ size: 1, order: 0 }} xs={{ size: 1, order: 0 }} className="account d-none d-sm-none d-md-none d-xl-none d-lg-block  ">
                                <Isvg src={account_icon} />
                                <Link to='/account'>Nalog</Link>

                                <Link to='/cart' className="cart-button">
                                    <Isvg src={cart_icon} />
                                    <div className="cart-text">
                                        <p>KORPA</p>

                                        <p>{this.props.cartCount} ARTIKLA</p>
                                    </div>
                                </Link>

                            </Col>
                            :
                            <Col lg={{ size: 3, order: 2 }} xs={{ size: 1, order: 0 }} className="account d-none d-sm-none d-md-none d-xl-block">
                                <div className="account-button">
                                    <Isvg src={account_icon} />
                                    <div className="account-text">
                                        <Link to='/login'>Prijava</Link>
                                        <Link to='/register'>Registracija</Link>
                                    </div>
                                </div>


                                <Link to='/cart' className="cart-button">
                                    <Isvg src={cart_icon} />
                                    <div className="cart-text">
                                        <p>KORPA</p>

                                        <p>{this.props.cartCount} ARTIKLA</p>
                                    </div>
                                </Link>

                            </Col>
                        }
                        <Col className='d-lg-none d-xl-none d-md-block' xs={{ size: 5, order: 4 }} md={{ size: 2, order: 4 }} className="mobile-actions">
                            <Link to='/cart'><Isvg src={cart_icon} /></Link>
                            <button onClick={() => this.setState({ showSearch: true })}><Isvg src={searchIcon} /></button>
                            <button onClick={() => { this.setState({ rightMenu: !this.state.rightMenu }) }}>
                                <Isvg src={category_icon} />
                            </button>
                        </Col>
                    </Row>

                </Container>

                <Container className='d-none d-sm-none d-md-none d-xl-block'>
                    <Row>
                        <Col lg="8">
                            <ul className="navigation">
                                <li><Link to='/'>Početna</Link></li>
                                <li><Link to='/category'>Proizvodi</Link>
                                <ul>
                                    {
                                        this.props.categories.map((item, idx) => {
                                            console.log(item);
                                            return (
                                                <li key={idx}><Link to={'/category' + item.Breadcrumb}>{item.Name}</Link></li>
                                            )
                                        }
                                    )}
                                </ul>



                                </li>
                                <li><Link to='/page/o-nama'>O nama</Link></li>

                                <li><Link to='/novosti'>Blog</Link></li>
                                <li><Link to='/contact'>Kontakt</Link></li>


                            </ul>
                        </Col>
                        <Col lg="4" className="contact-phone">
                            <Isvg src={phone_icon} /> 051 371 971
                        </Col>
                    </Row>

                </Container>

                {this.state.showSearch ?
                    <div className="search-container">
                        <button className="close" onClick={() => this.setState({ showSearch: false })}>
                            <i className="mdi mdi-close" />
                        </button>
                        <div className="input">
                            <input onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.props[0].history.push(`/category/&search=${this.state.search}`)
                                }
                            }} value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }) }} type="text" placeholder={"Pretražite..."} />
                            <button onClick={() => {
                                this.setState({
                                    showSearch: false
                                }, () => {
                                    this.props[0].history.push(`/category/&search=${this.state.search}`)
                                })
                            }}><Isvg src={searchIconWhite} /></button>
                        </div>
                    </div>
                    : null
                }

                <div className={this.state.leftMenu ? 'mobile-left-menu mobile-left-menu-open' : 'mobile-left-menu'}>
                    <div className="content" ref={(node) => this.leftMenuRef = node}>
                        <div className="user-area">
                            <div className="user-image">
                                <Isvg src={account_icon} />
                            </div>

                            {this.props.uData && <p>{this.props.uData.User.Name}</p>}
                            {this.props.uData && <p>{this.props.uData.User.EMail}</p>}
                            {!this.props.uData && <p><Link to='/login'>{this.props.translate('Prijava')}</Link> / <Link to='/register'>{this.props.translate('Registracija')}</Link></p>}

                        </div>

                        <ul>
                            <li> <Link to='/'><Isvg src={drawerHome} />Početna </Link> </li>
                            {this.props.uData && <li> <Link to='/account'><Isvg src={drawerAccount} /> Nalog </Link> </li>}
                            <li> <Link to='/cart'><Isvg src={drawerCart} />Korpa </Link> </li>
                            {this.props.uData && <li> <Link to='/account/wishlist'><Isvg src={drawerWishlist} />Lista omiljenih </Link> </li>}
                            <li> <Link to='/contact'><Isvg src={drawerPhone} />Kontakt </Link> </li>
                            {this.props.uData && <li> <a onClick={() => { this.props.setUserData(null); this.props.socketIOClient.emit('userLogout', {}); }}><Isvg src={drawerLogout} />Odjava </a> </li>}
                            {!this.props.uData && <li> <Link to='/account'><Isvg src={drawerAccount} />Prijava </Link> </li>}

                        </ul>
                    </div>
                </div>

                <div className={this.state.rightMenu ? 'mobile-right-menu mobile-right-menu-open' : 'mobile-right-menu'}>
                    <div className="content" ref={(node) => this.rightMenuRef = node}>
                        <h3>KATEGORIJE</h3>

                        <Categories {...this.props} selectCategory={(cat, level) => {
                            let state = { page: 0 };
                            state['_selectedCategory' + level] = cat._id;

                            this.setState(state);

                        }}

                            _selectedCategory0={this.state._selectedCategory0}
                            _selectedCategory1={this.state._selectedCategory1}
                            _selectedCategory2={this.state._selectedCategory2}
                            _selectedCategory3={this.state._selectedCategory3}
                            _selectedCategory4={this.state._selectedCategory4}


                        />


                    </div>
                </div>

            </header>

        );
    }
}

const mapStateToProps = state => ({
    searchForm: state.searchForm,
    menu: state.menu
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleChange: (val) => {
            dispatch(handleMobileSearchForm(val))
        },
        handleMenu: (val) => {
            dispatch(handleMenu(val))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
