import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import account_icon from '../assets/svg/user-icon.svg'
import list_icon from '../assets/svg/list-icon.svg'
import location_icon from '../assets/svg/location-icon.svg'
import heart_icon from '../assets/svg/heart.svg'
import key_icon from '../assets/svg/key-icon.svg'
import mail_icon from '../assets/svg/mail-icon.svg'
import logout_icon from '../assets/svg/logout-icon.svg'


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class AccountPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {

        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('userLogin', (data) => {
            console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
            }
        });



    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    login(data) {
        console.log(data);
        this.props.socketIOClient.emit("userLogin", data);
    }




    render() {
        let product = this.state.product;

        return (
            <div className="account-wrap">

                {!this.props.uData ? <Redirect to='/login' ></Redirect> : null}
                

                <Container>
                    <Row>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                        <li><Link to='/account'>{this.props.translate('Nalog')}</Link></li>

                                        </ul>
                                    </Col>
                                    <Col lg="9">
                                        <h1>{this.props.uData && this.props.uData.User.Name}</h1>
                                    </Col>
                                    <Col lg="3">
                                        <button className="button button-right big-button" onClick={() => {this.props.setUserData(null); this.props.socketIOClient.emit('userLogout', {});} }> <Isvg src={logout_icon} /> {this.props.translate('ODJAVA')}</button>
                                    </Col>
                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/edit'>
                                                        <div className="box">
                                                            <Isvg src={account_icon} />
                                                            <p>{this.props.translate('Upravljanje ličnim podacima')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/orders'>
                                                        <div className="box">
                                                            <Isvg src={list_icon} />
                                                            <p>{this.props.translate('Moje narudžbe')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/address'>
                                                        <div className="box">
                                                            <Isvg src={location_icon} />
                                                            <p>{this.props.translate('Adresa za dostavu')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/wishlist'>
                                                        <div className="box">
                                                            <Isvg src={heart_icon} />
                                                            <p>{this.props.translate('Lista omiljenih')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/change-password'>
                                                        <div className="box">
                                                            <Isvg src={key_icon} />
                                                            <p>{this.props.translate('Promijenite lozinku')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/newsletter'>
                                                        <div className="box">
                                                            <Isvg src={mail_icon} />
                                                            <p>{this.props.translate('Newsletter')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                            </Row>
                                        </Container>


                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                        <Col lg="3" className="d-none d-lg-block">
                    <Container>
                                <Row>
                                    {
                                        this.props.popularProducts.slice(Math.max(this.props.popularProducts.length - 3, 1)).map((item, idx) => {
                                            return (
                                                <Article
                                                    _id={item._id}
                                                    alias={item.Alias}
                                                    image={item.Images && item.Images.length && item.Images[0]}
                                                    title={item.Name}
                                                    package={item.package}
                                                    currency={this.props.currency}
                                                    discount={item.discount}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}>
                                                </Article>

                                            )
                                        })
                                    }
                                </Row>
                            </Container>
                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(AccountPage));
