import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import account_icon from '../assets/svg/user-icon.svg'
import list_icon from '../assets/svg/list-icon.svg'
import location_icon from '../assets/svg/location-icon.svg'
import heart_icon from '../assets/svg/heart.svg'
import key_icon from '../assets/svg/key-icon.svg'
import mail_icon from '../assets/svg/mail-icon.svg'
import logout_icon from '../assets/svg/logout-icon.svg'


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class ErrorPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }


    render() {
        let product = this.state.product;

        return (
            <div className="account-wrap">



                <Container>
                    <Row>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                            <li><Link to='/'>{this.props.translate('Početna')}</Link></li>

                                        </ul>
                                    </Col>

                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="12" className="not-found-content">
                                                    <h1>404</h1>
                                                    <h2>{this.props.translate('Stranica nije pronađena')}</h2>
                                                    <Link to='/' className="button">{this.props.translate('Vrati se na početnu stranicu')}</Link>


                                                </Col>

                                            </Row>
                                        </Container>

                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                        <Col lg="3" className="d-none d-lg-block">
                            <Container>
                                <Row>
                                    {
                                        this.props.popularProducts.slice(Math.max(this.props.popularProducts.length - 3, 1)).map((item, idx) => {
                                            return (
                                                <Article
                                                    _id={item._id}
                                                    alias={item.Alias}
                                                    image={item.Images && item.Images.length && item.Images[0]}
                                                    title={item.Name}
                                                    package={item.package}
                                                    currency={this.props.currency}
                                                    discount={item.discount}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}>
                                                </Article>

                                            )
                                        })
                                    }
                                </Row>
                            </Container>                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(ErrorPage));
